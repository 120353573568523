import { gql } from "@apollo/client";

// 获取解析记录列表
export const GET_CLOUD_FUNCTION_RECORD = gql`
  query Query($names: [String!]!) {
    cloudFunctionRecords(names: $names) {
      id
      name
      version
      payload
      type
      instanceId
      requestId
      duration
      memory
      result
      region
      createdAt
    }
  }
`;
