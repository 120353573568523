import React, { useState, useCallback, useRef, useEffect, memo } from 'react';
import { useAtom } from 'jotai';
import { Typography, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { VariableSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { useLazyQuery } from '@apollo/client';
import { GET_BOOKMARKS_FOR_ADMIN } from '@apis/BookmarkApi';
import { GET_BOOKMARKMETAS_FOR_ADMIN } from '@apis/BookmarkMetaApi';
import { getImageUrl, getReadableTime, getFaviconUrlByHost, getIconUrl } from '@utils/helper';
import { SOURCE } from './constant';
import { bookmarksAtom } from '../../atoms/bookmarks';

const COLUMN_COUNT = 4;
const DEFAULT_ROW_HEIGHT = 35;
const ITEMS_PER_PAGE = 50;
const TYPE = {
  IMAGE: 'IMAGE',
  FAVICON: 'FAVICON',
  DESCRIPTION: 'DESCRIPTION',
  PERSON: 'PERSON',
}

const ImageView = ({ data }) => {
  return (
    <Box
      component="img"
      crossOrigin="anonymous"
      src={getImageUrl(data.image, data.srcUrl || data.url, 'cover')} // TODO: 这里使用 ?? 会因为srcUrl为空字符串，导致问题
      sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    />
  )
}

const FaviconView = ({ data }) => {
  return (
    <Box
      component="img"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        aspectRatio: 1,
        ...!!data.faviconUrl ? {
          width: '32%',
          ml: '-16%',
          mt: '-16%',
        } : {
          width: '24%',
          ml: '-12%',
          mt: '-12%',
        }
      }}
      crossOrigin='anonymous'
      src={getIconUrl(data.icon, data.faviconUrl, data.url)}
    />
  )
}

const DescriptionView = ({ data }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        p: 1,
      }}
    >
      <Typography variant="body2" sx={{ lineHeight: '22px', wordBreak: 'break-all' }}>
        {data.description}
      </Typography>
    </Box>
  )
}

const Cell = memo(({ item, style, onItemClick, updateRowHeight, columnWidth }) => {
  const theme = useTheme();
  const cellRef = useRef(null);

  useEffect(() => {
    if (cellRef.current) {
      const height = cellRef.current.getBoundingClientRect().height;
      updateRowHeight(height);
    }
  }, [updateRowHeight, columnWidth]);

  if (!item) return null;

  let type = TYPE.FAVICON;

  if (item.image) {
    if (item.image === '<loading>') {
      type = TYPE.FAVICON;
    } else if (item.type === 'person') {
      type = TYPE.PERSON;
    } else {
      type = TYPE.IMAGE;
    }
  } else if (item.description) {
    type = TYPE.DESCRIPTION
  }

  return (
    <div 
      ref={cellRef} 
      style={{
        ...style,
        height: 'auto',
        minHeight: DEFAULT_ROW_HEIGHT,
        padding: 4,
      }}
    >
      <Box
        sx={{
          // p: 1,
          borderRadius: 3,
          border: 1,
          borderColor: theme.palette.divider,
          '&:hover': {
            backgroundColor: theme.palette.background.neutral,
          }
        }}>
        <Box
          position="relative"
          sx={{
            paddingTop: '56.25%',
            overflow: 'clip',
            borderRadius: 3,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '24%',
            cursor: 'pointer',
          }}
          onClick={() => onItemClick(item.id)}
        >
          { type === TYPE.IMAGE && <ImageView data={item} /> }
          { type === TYPE.DESCRIPTION && <DescriptionView data={item} /> }
          { type === TYPE.FAVICON && <FaviconView data={item} /> }
          {/* { type === TYPE.PERSON ?? <PersonView item={item} /> } */}
        </Box>
        <Box py={1} px={1.5}>
          <Box>
            <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>
              <Box
                component="img"
                sx={{
                  position: 'absolute',
                  top: 3.5,
                  left: 0,
                  width: 16,
                }}
                crossOrigin='anonymous'
                src={getFaviconUrlByHost(item.urlHost)}
              />
              <Typography sx={{
                fontSize: 15,
                lineHeight: '23px',
                fontWeight: 500,
                wordBreak: 'break-all',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                textIndent: theme.spacing(3),
              }}>{item.title}</Typography>
            </Box>
          </Box>
          <Stack 
            direction="row" 
            spacing={1} 
            divider={
              <Box 
                sx={{ 
                  width: '3px', 
                  height: '3px', 
                  backgroundColor: theme.palette.border, 
                  flexShrink: 0, 
                  borderRadius: 9999 
                }} 
              />
            }
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              flex: 1,
            }}
          >
            <Typography variant="caption" noWrap={true} color={theme.palette.text.secondary}>
              {item.creator?.nickname}
            </Typography>
            <Typography variant="caption" noWrap={true} color={theme.palette.text.secondary}>
              {getReadableTime(item.createdAt)}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </div>
  );
});

const BookmarkList = (props) => {
  const { source = SOURCE.USER, onItemClick } = props;

  const [items, setItems] = useAtom(bookmarksAtom);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [afterCursor, setAfterCursor] = useState(null);
  const gridRef = useRef();
  const rowHeights = useRef({});
  const loadingRef = useRef(false);

  const [getBookmarks] = useLazyQuery(source === SOURCE.META ? GET_BOOKMARKMETAS_FOR_ADMIN : GET_BOOKMARKS_FOR_ADMIN, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const bookmark = source === SOURCE.META ? data.bookmarkMetasForAdmin : data.bookmarksForAdmin;
      const newItems = bookmark.edges.map(edge => edge.node);
      setItems([
        ...items,
        ...newItems,
      ]);
      const afterCursor = bookmark.pageInfo.afterCursor;
      setHasNextPage(!!afterCursor);
      setAfterCursor(afterCursor);
      loadingRef.current = false;
    },
  });

  const isItemLoaded = useCallback((index) => index < items.length, [items]);

  const loadMoreItems = useCallback((startIndex, stopIndex) => {
    if (loadingRef.current || !hasNextPage) return Promise.resolve();
    loadingRef.current = true;
    return getBookmarks({
      variables: {
        first: ITEMS_PER_PAGE,
        after: afterCursor,
        field: 'UPDATED_AT',
        direction: 'DESC'
      }
    });
  }, [getBookmarks, afterCursor, hasNextPage]);
  
  const getRowHeight = useCallback((rowIndex) => {
    return rowHeights.current[rowIndex] || DEFAULT_ROW_HEIGHT;
  }, []);

  const cellRenderer = useCallback(({ rowIndex, columnIndex, style }) => {
    const index = rowIndex * COLUMN_COUNT + columnIndex;
    const item = items[index];

    const updateRowHeight = (height) => {
      if (height > (rowHeights.current[rowIndex] || 0)) {
        rowHeights.current[rowIndex] = height;
        if (gridRef.current) {
          gridRef.current.resetAfterRowIndex(rowIndex);
        }
      }
    };

    return (
      <Cell
        item={item}
        style={style}
        onItemClick={onItemClick}
        updateRowHeight={updateRowHeight}
        columnWidth={style.width}
      />
    );
  }, [items, onItemClick]);

  const handleResize = useCallback(({ width, height }) => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        rowIndex: 0,
        shouldForceUpdate: true,
      });
    }

    // 窗口大小变更，重置每行高度
    for (let i = 0; i < Object.keys(rowHeights.current).length; i++) {
      rowHeights.current[i] = DEFAULT_ROW_HEIGHT;
    }
  }, []);

  useEffect(() => {
    setItems([]);
  }, [source]);

  return (
    <AutoSizer onResize={handleResize}>
      {({ height, width }) => {
        const itemCount = hasNextPage ? items.length + ITEMS_PER_PAGE : items.length;
        const rowCount = Math.ceil(itemCount / COLUMN_COUNT);

        return (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <Grid
                ref={(grid) => {
                  gridRef.current = grid;
                  if (typeof ref === 'function') ref(grid);
                }}
                columnCount={COLUMN_COUNT}
                columnWidth={(index) => width / 4}
                height={height}
                rowCount={rowCount}
                rowHeight={getRowHeight}
                width={width}
                onItemsRendered={({ visibleRowStartIndex, visibleRowStopIndex }) => {
                  const visibleStartIndex = visibleRowStartIndex * COLUMN_COUNT;
                  const visibleStopIndex = (visibleRowStopIndex + 1) * COLUMN_COUNT - 1;
                  onItemsRendered({
                    overscanStartIndex: visibleStartIndex,
                    overscanStopIndex: visibleStopIndex,
                    visibleStartIndex,
                    visibleStopIndex,
                  });
                }}
              >
                {cellRenderer}
              </Grid>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};

export default BookmarkList;