import { gql } from "@apollo/client";

// 获取URL元信息
export const GET_BOOKMARK_META = gql`
  query BookmarkMeta($url: String!) {
    bookmarkMeta(url: $url) {
      id
    }
  }
`;

// 更新书签元数据（管理员）
export const UPDATE_BOOKMARK_META_FOR_ADMIN = gql`
  mutation($id: String!, $input: BookmarkMetaUpdateInput!) {
    bookmarkMetaUpdateForAdmin(id: $id, input: $input) {
      bookmarkMeta {
        id
        url
        title
        description
        faviconUrl
        image
        type
        extra
        card
        parser
        from
      }
    }
  }
`;

// 查询所有书签meta（管理员）
export const GET_BOOKMARKMETAS_FOR_ADMIN = gql`
  query bookmarkMetasForAdmin($first: Float, $after: String, $keywords: String, $field: BookmarkMetaSortByField!, $direction: SortByDirection!) {
    bookmarkMetasForAdmin(first: $first, orderBy: { field: $field, direction: $direction }, after: $after, keywords: $keywords) {
      edges {
        node {
          id
          title
          url
          srcUrl
          urlHost
          faviconUrl
          description
          image
          type
          extra
          card
          createdAt
          updatedAt
          from
          parser
          creator {
            nickname
          }
          theme
          icon
        }
      }
      pageInfo {
        afterCursor
      }
    }
  }
`;

// 获取书签meta完整详情
export const GET_BOOKMARK_META_FOR_ADMIN = gql`
query bookmarkMetaForAdmin($bookmarkMetaId: String) {
  bookmarkMetaForAdmin(id: $bookmarkMetaId) {
    id
    url
    srcUrl
    urlHost
    title
    description
    faviconUrl
    image
    type
    card
    from
    parser
    extraObj
  }
}
`;
