import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Box, Stack, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useLazyQuery } from '@apollo/client';
import { GET_BOOKMARK_META_FOR_ADMIN } from '@apis/BookmarkMetaApi';
import BookmarkMetaForm from './BookmarkMetaForm';
import { selectedBookmarkAtom, updateSelectedBookmarkAtom } from '../../atoms/bookmarks';

const TABS = {
  SUMMARY:'SUMMARY',
  META: 'META',
}

function BookmarkMetaDetail(props) {
  const {
    id,
  } = props;

  const [tab, setTab] = useState(TABS.SUMMARY);
  const [getBookmarkMeta] = useLazyQuery(GET_BOOKMARK_META_FOR_ADMIN, { fetchPolicy: 'network-only' });
  const [selectedBookmark] = useAtom(selectedBookmarkAtom);
  const [, updateSelectedBookmark] = useAtom(updateSelectedBookmarkAtom);

  useEffect(() => {
    if (id) {
      getBookmarkMeta({
        variables: { bookmarkMetaId: id },
        onCompleted: ({ bookmarkMetaForAdmin: bookmarkMeta }) => {
          updateSelectedBookmark(bookmarkMeta);
        }
      })
    }
  }, [id]);

  return !selectedBookmark ? (
    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ pt: 6 }}>
      <Typography variant="caption">
        请选择书签
      </Typography>
    </Stack>
  ) : (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={(evt, newValue) => { setTab(newValue); }}>
          <Tab label="编辑" value={TABS.SUMMARY} />
          <Tab label="详情" value={TABS.META} />
        </TabList>
      </Box>
      <Box sx={{ height: 'calc(100vh - 49px - 56px)', overflow: 'scroll' }}>
        <TabPanel value={TABS.SUMMARY} sx={{ p: 0 }}>
          {/* <BookmarkSummaryForm data={bookmarkData} /> */}
          <BookmarkMetaForm data={selectedBookmark} />
        </TabPanel>
        <TabPanel value={TABS.META} sx={{ p: 0 }}>
          {/* <BookmarkMetaForm data={bookmarkData} /> */}
        </TabPanel>
      </Box>
    </TabContext>
  );
}

export default BookmarkMetaDetail;
