import React, { useState, useCallback } from 'react';
import { TableContainer, Paper, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PageContainer from '../../components/PageContainer';
import BookmarkList from '../../components/BookmarkList';
import BookmarkDetail from '../../components/BookmarkDetail';

export default () => {
  const theme = useTheme();
  const [selectedBookmarkId, setSelectedBookmarkId] = useState();

  const handleItemClick = useCallback((id) => {
    setSelectedBookmarkId(id);
  }, []);

  return (
    <PageContainer title="书签管理">
      <TableContainer component={Paper}>
        <Stack spacing={2} direction="row">
          <Paper style={{ height: 'calc(100vh - 56px)', width: 'calc(100vw - 480px - 180px)' }}>
            <BookmarkList
              onItemClick={handleItemClick}
            />
          </Paper>
          <Box sx={{ width: 480, position: 'fixed', right: 0, top: 56, bottom: 0, px: 1 }}>
            <BookmarkDetail id={selectedBookmarkId} />
          </Box>
        </Stack>
      </TableContainer>
    </PageContainer>
  )
}
