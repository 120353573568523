import React, { useContext, useEffect } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import { styled, useTheme, alpha } from '@mui/material/styles';
import Sidebar from './Sidebar';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
}));


const MainStyle = styled('main')(({ theme, open }) => ({
  // flexGrow: 1,
  flex: 1,
  // display: 'flex',
  overflow: 'auto',
  width: '100%',
  // padding: theme.spacing(2),
  // ...(!open && {
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen
  //   }),
  //   [theme.breakpoints.up('md')]: {
  //     marginLeft: -DRAWER_WIDTH,
  //     width: `calc(100% - ${DRAWER_WIDTH}px)`
  //   },
  // }),
  // ...(open && {
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen
  //   }),
  //   marginLeft: 0,
  //   [theme.breakpoints.up('md')]: {
  //     width: `calc(100% - ${DRAWER_WIDTH}px)`
  //   },
  // }),
}));


export default () => {
  const theme = useTheme();

  return (
    <RootStyle>
      <Sidebar
        open={true}
        onToggle={() => {} }
      />
      <MainStyle theme={theme} open={true}>
        <Outlet />
      </MainStyle>
    </RootStyle>
  )
}