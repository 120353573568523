import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, FormControl, InputLabel, MenuItem, Select, Chip, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_PARSER, UPDATE_PARSER, GET_PARSERS } from '@apis/ParserApi';
import InputFormControl from '@components/InputFormControl';
import SelectFormControl from '@components/SelectFormControl';

export default (props) => {
  const {
    open,
    data,
    onClose,
    onSuccess,
  } = props;
  
  const [createParser] = useMutation(CREATE_PARSER);
  const [updateParser] = useMutation(UPDATE_PARSER);
  const [getParsers] = useLazyQuery(GET_PARSERS, { fetchPolicy: 'network-only' });

  const [parsers, setParsers] = useState([]);
  const [selectedParsers, setSelectedParsers] = useState([]);

  const initialValues = {
    name: '',
    description: '',
    regex: '',
    dynamicInject: true,
    mobile: false,
    isRedirectUrl: false,
    redirectParsers: [],
    redirectParserIds: [],
    keepUrlParams: '',
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().nullable(),
    }),
    onSubmit: async ({ name, description, regex, dynamicInject, mobile, isRedirectUrl, redirectParserIds, keepUrlParams }, { setSubmitting }) => {
      const input = {
        name,
        description,
        regex,
        dynamicInject,
        mobile,
        isRedirectUrl,
        redirectParserIds,
        keepUrlParams: keepUrlParams ? keepUrlParams.split(',') : [],
      };

      if (data?.id) {
        await updateParser({
          variables: {
            id: data?.id,
            input,
          },
        });
      } else {
        await createParser({
          variables: {
            input,
          },
        });
      }

      setSubmitting(false);
      onSuccess();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setValues } = formik;

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    if (open) {
      resetForm();
      setValues(initialValues);
      if (data) {
        setValues(Object.assign({}, initialValues, data, {
          keepUrlParams: data.keepUrlParams?.join(',') || '',
        }));
      }
    }
  }, [open]);

  useEffect(() => {
    if (values.isRedirectUrl) {
      getParsers({
        onCompleted: ({ parsers }) => {
          setParsers(parsers);
          const selected = parsers.filter(p => values.redirectParsers.includes(p.name));
          formik.setFieldValue('redirectParserIds', selected.map(p => p.id));
          setSelectedParsers(selected);
        }
      })
    }
  }, [values.isRedirectUrl]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            新增解析器
            <IconButton
              aria-label="close"
              onClick={handleCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <InputFormControl
                autoFocus
                label="名称"
                type="name"
                placeholder="名称"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                {...getFieldProps('name')}
              />
              <InputFormControl
                label="描述"
                type="description"
                placeholder="描述"
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                {...getFieldProps('description')}
              />
              <InputFormControl
                label="正则表达式"
                type="regex"
                placeholder="正则表达式"
                error={Boolean(touched.regex && errors.regex)}
                helperText={touched.regex && errors.regex}
                {...getFieldProps('regex')}
              />
              <InputFormControl
                label="保留URL参数"
                type="keepUrlParams"
                placeholder="保留URL参数"
                error={Boolean(touched.keepUrlParams && errors.keepUrlParams)}
                helperText={touched.keepUrlParams && errors.keepUrlParams}
                {...getFieldProps('keepUrlParams')}
              />
              <Stack direction="row" spacing={2}>
                <SelectFormControl
                  label="动态注入"
                  type="dynamicInject"
                  placeholder="动态注入"
                  error={Boolean(touched.dynamicInject && errors.dynamicInject)}
                  helperText={touched.dynamicInject && errors.dynamicInject}
                  {...getFieldProps('dynamicInject')}
                >
                  <MenuItem value={true}>是</MenuItem>
                  <MenuItem value={false}>否</MenuItem>
                </SelectFormControl>
                <SelectFormControl
                  label="移动端解析"
                  type="mobile"
                  placeholder="移动端解析"
                  error={Boolean(touched.mobile && errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  {...getFieldProps('mobile')}
                >
                  <MenuItem value={true}>是</MenuItem>
                  <MenuItem value={false}>否</MenuItem>
                </SelectFormControl>
                <SelectFormControl
                  label="是否是重定向URL"
                  type="isRedirectUrl"
                  placeholder="是否是重定向URL"
                  error={Boolean(touched.isRedirectUrl && errors.isRedirectUrl)}
                  helperText={touched.isRedirectUrl && errors.isRedirectUrl}
                  {...getFieldProps('isRedirectUrl')}
                >
                  <MenuItem value={true}>是</MenuItem>
                  <MenuItem value={false}>否</MenuItem>
                </SelectFormControl>
              </Stack>
              {
                values.isRedirectUrl ? (
                  <SelectFormControl
                    multiple
                    label="重定向解析器"
                    type="redirectParsers"
                    placeholder="重定向解析器"
                    value={values.redirectParserIds}
                    onChange={(event) => {
                      const selected = parsers.filter(p => event.target.value.includes(p.id));
                      setSelectedParsers(selected);
                      formik.setFieldValue('redirectParsers', selected.map(p => p.name));
                      formik.setFieldValue('redirectParserIds', selected.map(p => p.id));
                    }}
                    renderValue={() => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedParsers.map((parser) => (
                          <Chip key={parser.id} label={parser.name} />
                        ))}
                      </Box>
                    )}
                  >
                    {parsers.map(p => (
                      <MenuItem 
                        key={p.id} 
                        value={p.id}
                        selected={values.redirectParserIds.includes(p.id)}
                      >
                        {p.name}
                      </MenuItem>
                    ))}
                  </SelectFormControl>
                ) : null
              }
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>取消</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              保存
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
