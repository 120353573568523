import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Box, useMediaQuery, SwipeableDrawer, List, Stack, ListItemButton } from '@mui/material';

const WIDTH = 180;

const iOS =
  typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);


const StyledDrawer = styled(SwipeableDrawer)(({ theme, open }) => ({
  zIndex: 1202,
  width: open ? WIDTH : 'auto',
  '& .MuiDrawer-paper': {
    borderRight: `1px solid ${alpha(theme.palette.divider, .16)}`,
    width: WIDTH,
    // borderRight: 'none',
    // [theme.breakpoints.up('md')]: {
    //   top: 64,
    // }
  }
}));

Sidebar.propTypes = {
  open: PropTypes.bool,
  onToggle: PropTypes.func
};

export default function Sidebar({ open, onToggle }) {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const containerRef = useRef();
  const navigate = useNavigate();

  return (
    <Box
      // sx={{ width: matchUpMd ? WIDTH : 'auto' }}
      ref={containerRef}
    >
      <StyledDrawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={open}
        onClose={onToggle}
        onOpen={onToggle}
        ModalProps={{ keepMounted: true }}
        color="inherit"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        PaperProps={{
          sx: {
            backgroundColor: '#f9f9fa',
            // background: 'radial-gradient(circle at 120% 100%,  rgb(247, 239, 242) 0%,  rgb(238, 243, 247) 50%)',
            // backdropFilter: 'blur(64px)',
            // WebkitBackdropFilter: 'blur(64px)',
          }
        }}
      >
        <Stack
          sx={{ height: '100%' }}
        >
          <List>
            <ListItemButton sx={{ fontSize: 14 }} onClick={() => navigate('/parser')}>解析器管理</ListItemButton>
            <ListItemButton sx={{ fontSize: 14 }} onClick={() => navigate('/card')}>卡片管理</ListItemButton>
            <ListItemButton sx={{ fontSize: 14 }} onClick={() => navigate('/waitlist')}>候选名单管理</ListItemButton>
            <ListItemButton sx={{ fontSize: 14 }} onClick={() => navigate('/cloud-function-record')}>云函数执行记录</ListItemButton>
            <ListItemButton sx={{ fontSize: 14 }} onClick={() => navigate('/bookmark')}>书签管理</ListItemButton>
            <ListItemButton sx={{ fontSize: 14 }} onClick={() => navigate('/bookmark-meta')}>书签Metadata管理</ListItemButton>
          </List>
        </Stack>
      </StyledDrawer>
    </Box>
  )
}
