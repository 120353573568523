import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Box, Button, Stack, IconButton, Typography, Switch } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import { GET_PARSERS, UPDATE_PARSER } from '@apis/ParserApi';
import LoadingPanel from '@components/LoadingPanel';
import ParserFormDialog from './components/ParserFormDialog';
import PublishParserFormDialog from './components/PublishParserFormDialog';
import PageContainer from '../../components/PageContainer';

function Row(props) {
  const theme = useTheme();
  const { row, onUpdate, onPublish, onEnableChange } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        key={row.name}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          '& > *': { borderBottom: 'unset' }
        }}
      >
        <TableCell component="th" scope="row">
          <Typography>{row.name}</Typography>
          {
            row.description ? (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{row.description}</Typography>
            ) : null
          }
        </TableCell>
        <TableCell>{row.latestVersion?.version || '-'}</TableCell>
        <TableCell>{row.latestVersion?.createdAt ? dayjs(row.latestVersion?.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'}</TableCell>
        <TableCell>{row.latestVersion?.card || '-'}</TableCell>
        <TableCell>{row.latestVersion?.description || '-'}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Switch checked={row.enable} onChange={(evt) => onEnableChange(evt.target.checked)} />
        </TableCell>
        <TableCell>
          <Button
            size="small"
            onClick={onPublish}
          >
            发布
          </Button>
          <Button
            size="small"
            onClick={onUpdate}
          >
            编辑
          </Button>
          {/* <Button size="small">删除</Button> */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Stack direction="row" spacing={4}>
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>正则表达式</Typography>
                  <Typography variant="body1" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>{ row.regex }</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>动态注入</Typography>
                  <Typography variant="body1">{ row.dynamicInject ? '是' : '否' }</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>使用移动端解析</Typography>
                  <Typography variant="body1">{ row.mobile ? '是' : '否' }</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>是否包含重定向</Typography>
                  <Typography variant="body1">{ row.isRedirectUrl ? '是' : '否' }</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>重定向解析器</Typography>
                  <Typography variant="body1">{ row.redirectParsers.map(i => i).join(', ') || '-' }</Typography>
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>等待加载元素</Typography>
                <Typography variant="body1">{ row.latestVersion?.waitForSelector || '-' }</Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>解析脚本</Typography>
                <Typography variant="body1">{ row.latestVersion?.content || '-' }</Typography>
              </Stack>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default () => {
  const { loading, error, data, refetch } = useQuery(GET_PARSERS);
  const [updateParser] = useMutation(UPDATE_PARSER);
  const [parserFormDialogShow, setParserFormDialogShow] = useState(false);
  const [publishParserFormDialogShow, setPublishParserFormDialogShow] = useState(false);
  const [parserData, setParserData] = useState();

  const theme = useTheme();

  if (loading) {
    return <LoadingPanel height={72} size={16} />;
  }

  return (
    <PageContainer title="解析器管理">
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
        <Button
          type="primary"
          variant="contained"
          onClick={() => {
            setParserData(null);
            setParserFormDialogShow(true);
          }}
        >
          新增
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 180 }}>名称</TableCell>
              <TableCell style={{ width: 120 }}>版本</TableCell>
              <TableCell style={{ width: 220 }}>更新时间</TableCell>
              <TableCell style={{ width: 120 }}>卡片</TableCell>
              <TableCell>描述</TableCell>
              <TableCell style={{ width: 80 }}>详情</TableCell>
              <TableCell style={{ width: 80 }}>启用</TableCell>
              <TableCell style={{ width: 240 }}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.parsers.map((row) => (
              <Row
                key={row.id}
                row={row}
                onEnableChange={async (checked) => {
                  await updateParser({
                    variables: {
                      id: row.id,
                      input: {
                        enable: checked,
                      },
                    },
                  });
                  refetch();
                }}
                onUpdate={() => {
                  setParserData(row);
                  setParserFormDialogShow(true);
                }}
                onPublish={() => {
                  setParserData(row);
                  setPublishParserFormDialogShow(true);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ParserFormDialog
        open={parserFormDialogShow}
        data={parserData}
        onClose={() => { setParserFormDialogShow(false); }}
        onSuccess={() => {
          setParserFormDialogShow(false);
          refetch();
        }}
      />
      <PublishParserFormDialog
        open={publishParserFormDialogShow}
        data={parserData}
        onClose={() => { setPublishParserFormDialogShow(false); }}
        onSuccess={() => {
          setPublishParserFormDialogShow(false);
          refetch();
        }}
      />
    </PageContainer>
  )
}
