import React from 'react';
import { Typography, InputBase, FormControl, FormHelperText, Select } from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';

const StyledSelect = styled(Select)(({ theme, error }) => ({
  borderRadius: theme.spacing(1),
  '.MuiSelect-select': {
    padding: '10px 12px',
    margin: '2px 0',
  }
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.25),
})); 

export default (props) => {
  const {
    label,
    type,
    placeholder,
    autoComplete,
    error,
    helperText,
    ...otherProps
  } = props;

  const theme = useTheme();

  return (
    <FormControl fullWidth>
      { label && <StyledLabel>{label}</StyledLabel> }
      <StyledSelect
        fullWidth
        autoComplete={autoComplete}
        type={type}
        placeholder={placeholder}
        error={error}
        {...otherProps}
      />
      {helperText && (
        <FormHelperText error={error} sx={{ fontSize: 14, ml: 0 }}>
          { helperText }
        </FormHelperText>
      )}
    </FormControl>
  )
}