import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

export const bookmarksAtom = atom([]);

export const selectedBookmarkAtom = atom(null);

export const bookmarkAtomFamily = atomFamily((bookmarkId) => atom(
  (get) => get(bookmarksAtom).find((bookmark) => bookmark.id === bookmarkId) || null,
  (get, set, arg) => {
    const prev = get(bookmarksAtom)
    set(bookmarksAtom, { ...prev, [name]: { ...prev[name], ...arg } })
  },
));

export const updateBookmarkAtom = atom(
  null,
  (get, set, { bookmarkId, payload }) => {
    set(bookmarksAtom, get(bookmarksAtom).map(bookmark => 
      bookmark.id === bookmarkId ? { ...bookmark, ...payload } : bookmark
    ))
  }
)

export const updateSelectedBookmarkAtom = atom(
  null,
  (get, set, payload) => {
    set(selectedBookmarkAtom, payload)
  }
)