import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Collapse, Box, Stack, Button } from '@mui/material';
import { GET_CARDS } from '@apis/CardApi';
import LoadingPanel from '@components/LoadingPanel';
import dayjs from 'dayjs';
import PageContainer from '../../components/PageContainer';
import CardFormDialog from './components/CardFormDialog';
import PublishCardFormDialog from './components/PublishCardFormDialog';

export default () => {
  const { loading, error, data, refetch } = useQuery(GET_CARDS);
  const [cardFormDialogShow, setCardFormDialogShow] = useState(false);
  const [publishCardFormDialogShow, setPublishCardFormDialogShow] = useState(false);
  const [cardData, setCardData] = useState();

  if (loading) {
    return <LoadingPanel height={72} size={16} />;
  }

  return (
    <PageContainer title="卡片管理">
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
        <Button
          type="primary"
          variant="contained"
          onClick={() => {
            setCardData(null);
            setCardFormDialogShow(true);
          }}
        >
          新增
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 180 }}>名称</TableCell>
              <TableCell style={{ width: 120 }}>版本</TableCell>
              <TableCell>描述</TableCell>
              <TableCell style={{ width: 220 }}>更新时间</TableCell>
              <TableCell style={{ width: 240 }}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.cards.map((row) => (
              <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& > *': { borderBottom: 'unset' }
              }}
            >
              <TableCell component="th" scope="row">
                <Typography>{row.name}</Typography>
                {
                  row.description ? (
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>{row.description}</Typography>
                  ) : null
                }
              </TableCell>
              <TableCell>{row.latestVersion?.version || '-'}</TableCell>
              <TableCell>{row.latestVersion?.description || '-'}</TableCell>
              <TableCell>{row.latestVersion?.createdAt ? dayjs(row.latestVersion?.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'}</TableCell>
              <TableCell>
                <Button
                  size="small"
                  onClick={() => {
                    setCardData(row);
                    setPublishCardFormDialogShow(true);
                  }}
                >
                  发布
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setCardData(row);
                    setCardFormDialogShow(true);
                  }}
                >
                  编辑
                </Button>
                {/* <Button size="small">删除</Button> */}
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CardFormDialog
        open={cardFormDialogShow}
        data={cardData}
        onClose={() => { setCardFormDialogShow(false); }}
        onSuccess={() => {
          setCardFormDialogShow(false);
          refetch();
        }}
      />
      <PublishCardFormDialog
        open={publishCardFormDialogShow}
        data={cardData}
        onClose={() => { setPublishCardFormDialogShow(false); }}
        onSuccess={() => {
          setPublishCardFormDialogShow(false);
          refetch();
        }}
      />
    </PageContainer>
  )
}
