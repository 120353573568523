import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import SnackbarProvider from '@contexts/snackbar/SnackbarProvider';
import { useTheme } from '@mui/material/styles';
import MainLayout from './layouts/MainLayout';
import ParserPage from './pages/Parser';
import CardPage from './pages/Card';
import BookmarkPage from './pages/Bookmark';
import WaitlistPage from './pages/Waitlist';
import CloudFunctionRecordPage from './pages/CloudFunctionRecord';
import BookmarkMetaPage from './pages/BookmarkMeta';

function LayourWrapper({ isMobile }) {
  return (
    <SnackbarProvider>
      <MainLayout />
    </SnackbarProvider>
  )
}

export default function Router() {
  const theme = useTheme();

  return useRoutes([
    {
      path: '/',
      element: <LayourWrapper />,
      children: [
        { path: '/parser', element: <ParserPage /> },
        { path: '/card', element: <CardPage /> },
        { path: '/bookmark', element: <BookmarkPage /> },
        { path: '/waitlist', element: <WaitlistPage /> },
        { path: '/cloud-function-record', element: <CloudFunctionRecordPage /> },
        { path: '/bookmark-meta', element: <BookmarkMetaPage /> },
      ]
    },
  ]);
}
