import React from 'react';
import { useQuery } from '@apollo/client';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Collapse, Box, Stack, Button } from '@mui/material';
import { GET_WAITLIST } from '@apis/WaitlistApi';
import LoadingPanel from '@components/LoadingPanel';
import dayjs from 'dayjs';
import PageContainer from '../../components/PageContainer';

export default () => {
  const { loading, error, data, refetch } = useQuery(GET_WAITLIST);

  if (loading) {
    return <LoadingPanel height={72} size={16} />;
  }

  return (
    <PageContainer title="候选名单管理">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>邮箱地址</TableCell>
              <TableCell style={{ width: 220 }}>创建时间</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.waitlist.map((row) => (
              <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '& > *': { borderBottom: 'unset' }
              }}
            >
              <TableCell component="th" scope="row">
                <Typography>{row.email}</Typography>
              </TableCell>
              <TableCell>{row.createdAt ? dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-'}</TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  )
}
