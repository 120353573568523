import { gql } from "@apollo/client";

// 获取解析器列表
export const GET_PARSERS = gql`
  query Parsers {
    parsers {
      id
      name
      description
      regex
      dynamicInject
      enable
      mobile
      keepUrlParams
      isRedirectUrl
      redirectParsers
      latestVersion {
        card
        version
        content
        cardVersionId
        createdAt
        waitForSelector
      }
    }
  }
`;

// 添加解析器
export const CREATE_PARSER = gql`
  mutation ParserCreate($input: ParserCreateInput!) {
    parserCreate(input: $input) {
      parser {
        id
      }
    }
  }
`;

// 更新解析器
export const UPDATE_PARSER = gql`
  mutation ParserUpdate($id: String!, $input: ParserUpdateInput!) {
    parserUpdate(id: $id, input: $input) {
      parser {
        id
      }
    }
  }
`;

// 发布解析器
export const PUBLISH_PARSER = gql`
  mutation ParserVersionCreate($input: ParserVersionCreateInput!) {
    parserVersionCreate(input: $input) {
      parserVersion {
        id
      }
    }
  }
`;

// 云端解析
export const CLOUD_PARSER = gql`
  mutation ParserCloud($url: String!) {
    parserCloud(url: $url) {
      result
    }
  }
`;
