import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Box, Stack, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useLazyQuery } from '@apollo/client';
import { GET_BOOKMARK_FOR_ADMIN } from '@apis/BookmarkApi';
import BookmarkForm from './BookmarkForm';
import { selectedBookmarkAtom, updateSelectedBookmarkAtom } from '../../atoms/bookmarks';

const TABS = {
  EDIT:'EDIT',
  DETAIL: 'DETAIL',
}

function BookmarkDetail(props) {
  const {
    id,
  } = props;

  const [tab, setTab] = useState(TABS.EDIT);
  const [getBookmark] = useLazyQuery(GET_BOOKMARK_FOR_ADMIN, { fetchPolicy: 'network-only' });
  const [selectedBookmark] = useAtom(selectedBookmarkAtom);
  const [, updateSelectedBookmark] = useAtom(updateSelectedBookmarkAtom);

  useEffect(() => {
    if (id) {
      getBookmark({
        variables: { bookmarkId: id },
        onCompleted: ({ bookmarkForAdmin: bookmark }) => {
          updateSelectedBookmark(bookmark);
        }
      })
    }
  }, [id]);

  return !selectedBookmark ? (
    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ pt: 6 }}>
      <Typography variant="caption">
        请选择书签
      </Typography>
    </Stack>
  ) : (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={(evt, newValue) => { setTab(newValue); }}>
          <Tab label="编辑" value={TABS.EDIT} />
          <Tab label="详情" value={TABS.DETAIL} />
        </TabList>
      </Box>
      <Box sx={{ height: 'calc(100vh - 49px - 56px)', overflow: 'scroll' }}>
        <TabPanel value={TABS.EDIT} sx={{ p: 0 }}>
          <BookmarkForm data={selectedBookmark} />
        </TabPanel>
        <TabPanel value={TABS.DETAIL} sx={{ p: 0 }}>
        </TabPanel>
      </Box>
    </TabContext>
  );
}

export default BookmarkDetail;
