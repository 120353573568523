import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'jotai';
import { jotaiStore } from './libs/jotai';
import App from './App';

export default () => (
  <HelmetProvider>
    <BrowserRouter basename="/admin">
      <Provider store={jotaiStore}>
        <App />
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);
