import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useMutation, useLazyQuery } from '@apollo/client';
import { inc } from 'semver';
import { PUBLISH_PARSER } from '@apis/ParserApi';
import { GET_CARDS } from '@apis/CardApi';
import InputFormControl from '@components/InputFormControl';
import SelectFormControl from '@components/SelectFormControl';

export default (props) => {
  const {
    open,
    data,
    onClose,
    onSuccess,
  } = props;
  
  const [publishParser] = useMutation(PUBLISH_PARSER);
  const [getCards] = useLazyQuery(GET_CARDS, { fetchPolicy: 'network-only' });
  
  const [cards, setCards] = useState([]);

  const initialValues = {
    name: '',
    version: '',
    content: '',
    cardVersionId: 'common',
    waitForSelector: '',
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      version: Yup.string().trim().required(),
      content: Yup.string().trim().required(),
      cardVersionId: Yup.string().trim().required(),
    }),
    onSubmit: async ({ version, content, cardVersionId, waitForSelector }, { setSubmitting }) => {
      const input = {
        parserId: data.id,
        version,
        content,
        cardVersionId: cardVersionId === 'common' ? null : cardVersionId,
        waitForSelector,
      };

      await publishParser({
        variables: {
          input,
        },
      });

      setSubmitting(false);
      onSuccess();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setValues } = formik;

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    if (open) {
      getCards({
        onCompleted: ({ cards }) => {
          setCards(cards);
        }
      })

      resetForm();
      const { name, latestVersion } = data;
      if (data) {
        setValues(Object.assign({}, {
          name,
          version: inc(latestVersion?.version || '0.0.0', 'patch'),
          cardVersionId: latestVersion?.cardVersionId || 'common',
          waitForSelector: latestVersion?.waitForSelector,
        }));
      } else {
        setValues(initialValues);
      }
    }
  }, [open]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            发布解析器
            <IconButton
              aria-label="close"
              onClick={handleCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <InputFormControl
                label="名称"
                type="name"
                placeholder="名称"
                readOnly
                disabled
                {...getFieldProps('name')}
              />
              <InputFormControl
                label="版本"
                type="version"
                placeholder="版本"
                // readOnly
                // disabled
                helperText={touched.version && errors.version}
                {...getFieldProps('version')}
              />
              {/* <InputFormControl
                label="卡片"
                type="cardVersionId"
                placeholder="卡片"
                error={Boolean(touched.cardVersionId && errors.cardVersionId)}
                helperText={touched.cardVersionId && errors.cardVersionId}
                {...getFieldProps('cardVersionId')}
              /> */}
              <SelectFormControl
                label="卡片"
                type="cardVersionId"
                placeholder="卡片"
                error={Boolean(touched.cardVersionId && errors.cardVersionId)}
                helperText={touched.cardVersionId && errors.cardVersionId}
                {...getFieldProps('cardVersionId')}
              >
                <MenuItem key="common" value="common">默认</MenuItem>
                {
                  cards.map(card => (
                    <MenuItem key={card.latestVersion.id} value={card.latestVersion.id}>{card.name} - {card.latestVersion.version}</MenuItem>
                  ))
                }
              </SelectFormControl>
              <InputFormControl
                label="等待加载元素"
                type="waitForSelector"
                placeholder="等待加载元素"
                error={Boolean(touched.waitForSelector && errors.waitForSelector)}
                helperText={touched.waitForSelector && errors.waitForSelector}
                {...getFieldProps('waitForSelector')}
              />
              <InputFormControl
                label="解析器脚本"
                type="content"
                placeholder="解析器脚本"
                multiline={true}
                minRows={3}
                maxRows={5}
                error={Boolean(touched.content && errors.content)}
                helperText={touched.content && errors.content}
                {...getFieldProps('content')}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>取消</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              保存
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
