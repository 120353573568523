import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { CREATE_CARD, UPDATE_CARD } from '@apis/CardApi';
import InputFormControl from '@components/InputFormControl';

export default (props) => {
  const {
    open,
    data,
    onClose,
    onSuccess,
  } = props;
  
  const [createCard] = useMutation(CREATE_CARD);
  const [updateCard] = useMutation(UPDATE_CARD);

  const initialValues = {
    name: '',
    description: '',
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().nullable(),
    }),
    onSubmit: async ({ name, description }, { setSubmitting }) => {
      const input = {
        name,
        description,
      };

      if (data?.id) {
        await updateCard({
          variables: {
            id: data?.id,
            input,
          },
        });
      } else {
        await createCard({
          variables: {
            input,
          },
        });
      }

      setSubmitting(false);
      onSuccess();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setValues } = formik;

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    if (open) {
      resetForm();
      setValues(initialValues);
      if (data) {
        setValues(Object.assign({}, data));
      }
    }
  }, [open]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>
            新增卡片
            <IconButton
              aria-label="close"
              onClick={handleCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <InputFormControl
                autoFocus
                label="名称"
                type="name"
                placeholder="名称"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                {...getFieldProps('name')}
              />
              <InputFormControl
                label="描述"
                type="description"
                placeholder="描述"
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                {...getFieldProps('description')}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>取消</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              保存
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
