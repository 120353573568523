import React from 'react';
import { Typography, AppBar, Toolbar, Stack } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';

export default (props) => {
  const {
    title,
    children,
  } = props;

  const theme = useTheme();

  return (
    <>
      <AppBar elevation={0} style={{ left: '180px', width: 'calc(100% - 180px)', backgroundColor: theme.palette.background.paper, boxShadow: `inset 0 -1px 0 ${alpha(theme.palette.divider, .16)}`, }}>
        <Toolbar>
          <Typography
            variant="h2"
            component="h2"
            sx={{ flexGrow: 1, color: theme.palette.text.primary, fontWeight: theme.typography.fontWeightMedium }}
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack sx={{ paddingTop: '56px' }}>
        {children}
      </Stack>
    </>
  )
}
