import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Typography, Stack, List, ListItem, ListItemText, Divider, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { GET_CLOUD_FUNCTION_RECORD } from '@apis/CloudFunctionRecordApi';
import PageContainer from '../../components/PageContainer';

export default () => {
  const theme = useTheme();
  const [getData] = useLazyQuery(GET_CLOUD_FUNCTION_RECORD, { fetchPolicy: 'network-only' });
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({
    'adv-parser': true,
    'basic-parser': false,
  });

  useEffect(() => {
    const names = [];
    for (let i in selected) {
      if (selected[i]) {
        names.push(i);
      }
    }

    getData({
      variables: {
        names,
      },
      onCompleted: ({ cloudFunctionRecords }) => {
        setData(cloudFunctionRecords);
      }
    })
  }, [selected]);

  return (
    <PageContainer title="云函数执行记录">
      <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 2 }}>
        <Chip
          label="adv-parser"
          variant={selected['adv-parser'] ? 'filled' : 'outlined'}
          onClick={() => {
            setSelected({ ...selected, 'adv-parser': true });
          }}
          onDelete={selected['adv-parser'] ? () => {
            setSelected({ ...selected, 'adv-parser': false });
          } : null}
        />
        <Chip
          label="basic-parser"
          variant={selected['basic-parser'] ? 'filled' : 'outlined'}
          onClick={() => {
            setSelected({ ...selected, 'basic-parser': true });
          }}
          onDelete={selected['basic-parser'] ? () => {
            setSelected({ ...selected, 'basic-parser': false });
          } : null}
        />
      </Stack>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {
          data.map((row) => {
            const payloadRegex = /(https?:\/\/[^\s"]+)/g;
            const payloadMatches = row.payload.match(payloadRegex);
            const payloadParts = row.payload.split(payloadRegex);

            const resultRegex = /(\[error\])/g
            const resultMatches = row.result.match(resultRegex);
            const resultParts = row.result.split(resultRegex);
            return (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={<Typography variant="body2">{row.name} <Typography variant="caption" sx={{ color: 'divider', mx: 1 }}>/</Typography> {row.region} <Typography variant="caption" sx={{ color: 'divider', mx: 1 }}>/</Typography> {dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>}
                    secondary={
                      <>
                        <Typography
                          paragraph
                          variant="body2"
                          color="text.secondary"
                        >
                          {payloadParts.map((part, index) => {
                            if (payloadMatches && payloadMatches.includes(part)) {
                              return (
                                <a target="_blank" href={part} key={index} style={{ color: theme.palette.primary.main }}>
                                  {part}
                                </a>
                              );
                            }
                            return <React.Fragment key={index}>{part}</React.Fragment>;
                          })}
                        </Typography>
                        <Typography
                          paragraph
                          variant="body2"
                          color="text.secondary"
                          sx={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                        >
                          {resultParts.map((part, index) => {
                            if (resultMatches && resultMatches.includes(part)) {
                              return (
                                <span key={index} style={{ color: theme.palette.error.main, fontWeight: 'bold' }}>
                                  {part}
                                </span>
                              );
                            }
                            return <React.Fragment key={index}>{part}</React.Fragment>;
                          })}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </>
            )
          })
        }
      </List>
    </PageContainer>
  )
}
