import React, { useEffect, useState, useContext } from 'react';
import { useAtom } from 'jotai';
import { Stack, Box, Button, InputAdornment, IconButton } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import InputFormControl from '@components/InputFormControl';
import { BOOKMARK_CLOUSE_PARSER_ASYNC } from '@apis/BookmarkApi';
import { UPDATE_BOOKMARK_META_FOR_ADMIN } from '@apis/BookmarkMetaApi';
import { SnackbarContext } from '@contexts/snackbar/snackbarReducer';
import { SHOW_SNACKBAR } from '@contexts/snackbar/SnackbarActionTypes';
import { sendRequestToBrowserExtension } from '@utils/helper';
import { MSG_REQUEST_UPLOAD_IMAGE } from '@shared-constants/message';
import { updateBookmarkAtom } from '../../atoms/bookmarks';

function BookmarkMetaForm(props) {
  const {
    data,
  } = props;

  const [initialValues, setInitialValues] = useState();
  const [bookmarkMetaId, setBookmarkMetaId] = useState();
  const { dispatch: snackbarDispatch } = useContext(SnackbarContext);
  const [updateBookmarkMetaForAdmin] = useMutation(UPDATE_BOOKMARK_META_FOR_ADMIN);
  const [cloudParserBookmarkAsync] = useMutation(BOOKMARK_CLOUSE_PARSER_ASYNC);
  const [, updateBookmark] = useAtom(updateBookmarkAtom);

  const getInitialValue = (value) => ({
    title: value.title || '',
    url: value.url || '',
    srcUrl: value.srcUrl || '',
    urlHost: value.urlHost || '',
    description: value.description || '',
    image: value.image || '',
    faviconUrl: value.faviconUrl || '',
    type: value.type || '',
    card: value.card || '',
    from: value.from || '',
    parser: value.parser || '',
    extra: JSON.stringify(value.extraObj || ''),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      // name: Yup.string().trim().nullable(),
    }),
    onSubmit: async ({ urlHost, ...values }, { setSubmitting }) => {
      updateBookmarkMetaForAdmin({
        variables: {
          id: bookmarkMetaId,
          input: values,
        },
        onCompleted: ({ bookmarkMetaUpdateForAdmin: { bookmarkMeta } }) => {
          setSubmitting(false);
          setInitialValues(values);
          snackbarDispatch({ type: SHOW_SNACKBAR, data: '更新成功' });

          updateBookmark({
            bookmarkId: data.id,
            payload: bookmarkMeta,
          });
        },
        onError: (ex) => {
          console.log(ex);
          setSubmitting(false);
          snackbarDispatch({ type: SHOW_SNACKBAR, data: '更新失败' });
        }
      });

      setSubmitting(false);
    }
  });

  const handleReParse = async () => {
    // cloudParserBookmarkAsync({
    //   variables: {
    //     input: {
    //       id: bookmarkId,
    //       from: `web@${$CONFIG.apps.home}:RE_CLOUD_ADV`,
    //     }
    //   },
    //   onCompleted: (data) => {
    //     snackbarDispatch({ type: SHOW_SNACKBAR, data: '已开始解析' });
    //   },
    //   onError: (ex) => {
    //     console.log(ex);
    //     snackbarDispatch({ type: SHOW_SNACKBAR, data: '执行解析失败' });
    //   }
    // });
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setValues, dirty } = formik;

  /**
   * 上传图片
   */
  async function handleUploadImage() {
    try {
      const payload = {
        type: 'image',
        imageUrl: values.image,
        urlHost: values.urlHost,
      }
      await sendRequestToBrowserExtension(MSG_REQUEST_UPLOAD_IMAGE, payload);
      snackbarDispatch({ type: SHOW_SNACKBAR, data: '图片上传成功' });
    } catch (ex) {
      snackbarDispatch({ type: SHOW_SNACKBAR, data: '图片上传失败' });
    }
  }

  /**
   * 上传图标
   */
  async function handleUploadFavicon() {
    try {
      const payload = {
        type: 'favicon',
        imageUrl: values.image,
        urlHost: values.urlHost,
      }
      await sendRequestToBrowserExtension(MSG_REQUEST_UPLOAD_IMAGE, payload);
      snackbarDispatch({ type: SHOW_SNACKBAR, data: '图片上传成功' });
    } catch (ex) {
      snackbarDispatch({ type: SHOW_SNACKBAR, data: '图片上传失败' });
    }
  }
  
  useEffect(() => {
    if (data) {
      resetForm();
      setBookmarkMetaId(data.id);
      setInitialValues(getInitialValue(data));
    }
  }, [data]);

  return (
    <Box>
      <FormikProvider value={formik}>
       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ p: 2 }}>
            <InputFormControl
              autoFocus
              label="标题"
              type="title"
              placeholder="标题"
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              {...getFieldProps('title')}
            />
            <InputFormControl
              label="URL"
              type="text"
              placeholder="URL"
              error={Boolean(touched.url && errors.url)}
              helperText={touched.url && errors.url}
              {...getFieldProps('url')}
            />
            <InputFormControl
              label="源URL"
              type="text"
              placeholder="源URL"
              error={Boolean(touched.srcUrl && errors.srcUrl)}
              helperText={touched.srcUrl && errors.srcUrl}
              {...getFieldProps('srcUrl')}
            />
            <InputFormControl
              label="URL Host"
              type="text"
              placeholder="URL Host"
              disabled={true}
              error={Boolean(touched.urlHost && errors.urlHost)}
              helperText={touched.urlHost && errors.urlHost}
              {...getFieldProps('urlHost')}
            />
            <InputFormControl
              label="描述"
              type="text"
              placeholder="描述"
              multiline={true}
              minRows={3}
              maxRows={5}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              {...getFieldProps('description')}
            />
            <InputFormControl
              label="图片"
              type="text"
              placeholder="图片"
              error={Boolean(touched.image && errors.image)}
              helperText={touched.image && errors.image}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleUploadImage}>
                    <UploadIcon />
                  </IconButton>
                </InputAdornment>
              }
              {...getFieldProps('image')}
            />
            <InputFormControl
              label="图标"
              type="text"
              placeholder="图标"
              error={Boolean(touched.faviconUrl && errors.faviconUrl)}
              helperText={touched.faviconUrl && errors.faviconUrl}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleUploadFavicon}>
                    <UploadIcon />
                  </IconButton>
                </InputAdornment>
              }
              {...getFieldProps('faviconUrl')}
            />
            <InputFormControl
              label="类型"
              type="text"
              placeholder="类型"
              error={Boolean(touched.type && errors.type)}
              helperText={touched.type && errors.type}
              {...getFieldProps('type')}
            />
            <InputFormControl
              label="解析数据"
              type="text"
              placeholder="解析数据"
              multiline={true}
              minRows={3}
              maxRows={5}
              error={Boolean(touched.extra && errors.extra)}
              helperText={touched.extra && errors.extra}
              {...getFieldProps('extra')}
            />
            <InputFormControl
              label="卡片"
              type="text"
              placeholder="卡片"
              error={Boolean(touched.card && errors.card)}
              helperText={touched.card && errors.card}
              {...getFieldProps('card')}
            />
            <InputFormControl
              label="解析器"
              type="text"
              placeholder="解析器"
              error={Boolean(touched.parser && errors.parser)}
              helperText={touched.parser && errors.parser}
              {...getFieldProps('parser')}
            />
            <InputFormControl
              label="来源"
              type="text"
              placeholder="来源"
              error={Boolean(touched.from && errors.from)}
              helperText={touched.from && errors.from}
              {...getFieldProps('from')}
            />
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ position: 'sticky', bottom: 0, bgcolor: 'background.paper', px: 2, py: 1 }}>
            <Button disabled onClick={() => { handleReParse(); }}>重新解析</Button>
            <Stack direction="row" spacing={2}>
              <Button onClick={() => { resetForm(); }}>重置</Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} loadingPosition="start" disabled={!dirty}>保存</LoadingButton>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </Box>
  );
}

export default BookmarkMetaForm;
